/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ayer leí una ", React.createElement(_components.a, {
    href: "http://www.k-government.com/2012/09/13/programando-para-un-mejor-gobierno/"
  }, "noticia sobre aplicaciones cívicas"), " donde se exponía un claro ejemplo de personalización, se creó una aplicación ciudadana para un uso específico y luego cada ciudad descargaba su código fuente y la modificaba según sus necesidades y la liberaba, todo esto de una forma muchísimo más rápida que por los canales habituales. Aquí está la gran ventaja de la producción P2P, la innovación de alta velocidad y para que se produzca esta innovación solo es necesario personalizar diseños libres.", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2012/09/ghyesferas.jpg"
  }, React.createElement(_components.img, {
    src: "http://litox9.files.wordpress.com/2012/09/ghyesferas.jpg?w=300",
    alt: "Ejemplo de diseño CAD paramétrico",
    title: "ghyesferas"
  }))), "\n", React.createElement(_components.p, null, "Primero que nada necesitamos un ", React.createElement(_components.strong, null, "código fuente"), ", no en el sentido estricto de la palabra, puede ser cualquier cosa: planos CAD, código, un proyecto…pero la condición necesaria que debe cumplir es que su licencia sea libre, para poder modificarlo y usarlo sin restricciones."), "\n", React.createElement(_components.p, null, "Con este código ya podemos empezar la modificación, adaptándolo a las necesidades de nuestra comunidad, si quisiéramos que se adaptara a cada persona, ya estaríamos entrando en el campo de la personotecnia, campo complicado donde los haya."), "\n", React.createElement(_components.p, null, "Una forma de facilitar esta personalización es utilizar el ", React.createElement(_components.a, {
    href: "http://www.parametriccamp.com/%C2%BFque-es-el-diseno-parametrico/"
  }, "diseño paramétrico"), ", las herramientas de CAD cada vez tienen más en cuenta este tipo de diseño que facilita mucho las personalización y además es un campo que se está extendiendo debido a la aparición de las impresoras 3D."), "\n", React.createElement(_components.p, null, "Mediante diseño modular y personalización podemos subirnos al carro de la ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2012/09/09/el-modo-de-produccion-p2p/"
  }, "producción P2P"), ", no es difícil, pero hacen falta trabajo y ganas, la ventaja como ya he dicho es la innovación de alta velocidad que nos ayude a romper el mundo de lentos gigantes que tenemos hoy en día."), "\n", React.createElement(_components.p, null, "Nota: Es posible que el término personalización no sea el mas correcto porque como en el ejemplo no tiene porqué referirse a una sola persona. Pero no conozco una palabra más correcta, podría proponer alguna como ", React.createElement(_components.em, null, "comunidación"), ", para destacar el carácter de comunidad que debería tener, pero suena un poco rara. ¿Hay algún filólogo en la sala?"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
